import "./styles.css";
import axios from "axios";
import * as GoogleSpreadsheet from "google-spreadsheet";
import { useEffect, useState } from "react";
import AnimatedNumber from "animated-number-react";

var img1 = "https://5amclub.co.in/AyurvedApril/1.png";
var img2 = "https://5amclub.co.in/AyurvedApril/2.png";
var img3 = "https://5amclub.co.in/AyurvedApril/3.png";
var img4 = "https://5amclub.co.in/AyurvedApril/4.png";
var img5 = "https://5amclub.co.in/AyurvedApril/5.png";
var img6 = "https://5amclub.co.in/AyurvedApril/6.png";
var img7 = "https://5amclub.co.in/AyurvedApril/7.png";
var img8 = "https://5amclub.co.in/AyurvedApril/8.png";
var img9 = "https://5amclub.co.in/AyurvedApril/9.png";
var img10 = "https://5amclub.co.in/AyurvedApril/10.png";
var img11 = "https://5amclub.co.in/AyurvedApril/11.png";
var img12 = "https://5amclub.co.in/AyurvedApril/12.png";
var img13 = "https://5amclub.co.in/AyurvedApril/13.png";
var img14 = "https://5amclub.co.in/AyurvedApril/14.png";
var img15 = "https://5amclub.co.in/AyurvedApril/15.png";
var img16 = "https://5amclub.co.in/AyurvedApril/16.png";

export default function App() {
  const coreMembers = 10;

  var [count, setCount] = useState("Loading...");

  useEffect(() => {
    axios
      .get(
        "https://asia-south1-wakeup-at-5amclub.cloudfunctions.net/getMemberCount"
      )
      .then((res) => {
        console.log("Responce of live counter ", res);
        setCount(res.data.count + 10);
        // setCount(13);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);

  // const doc2 = new GoogleSpreadsheet.GoogleSpreadsheet(
  //   "1Fxmh4TtSXUPzcSgjXDCsCmfABzktY2qpZjxVVhDrx1Y"
  // );
  // doc2.useApiKey("AIzaSyD-UGxXCYMwgCxt2bPwtQc9vnY8zw9oRak");
  // doc2.loadInfo().then(() => {
  //   const sheet = doc2.sheetsByIndex[0];
  //   sheet
  //     .getRows()
  //     .then((val) => {
  //       const filteredRows = val.filter(
  //         (row) => row._rawData[0] === "captured"
  //       );
  //       setCount(filteredRows.length + coreMembers);
  //     })
  //     .catch((err) => console.log(err));
  // });

  var formatValue = (value) => `${Number(value).toFixed(0)} `;

  return (
    <div className="App">
      {/* <a href="https://5amclub.typeform.com/to/lI1P2Be1" className="underline">
        <div className="btn-join-now">
          Secure Your Spot Now<i className="bx bx-link-external topen"></i>
        </div>
      </a> */}
      {/* <div className="ConterHolder">
        <span className="CounterTitle">Ayurved April • 1 - 21 April</span>
        <div className="Counter">
          <AnimatedNumber
            className="Number"
            value={count}
            duration={1200}
            formatValue={formatValue}
          />
          <span className="Number2">/ 100</span>
        </div>
        <span className="CounterNote">Only 100 Slots Available</span>
      </div> */}

      {/* <a href="#" className="underline">
        <div className="btn-join-now ">
            Click Here to Know
        </div>
        </a> */}
      <div className="Container">
        <img src={img1} />
        <img src={img2} />
        <img src={img3} />
        <img src={img4} />
        <img src={img5} />
        <img src={img6} />
        <img src={img7} />
        <img src={img8} />
        <img src={img9} />
        <img src={img10} />
        <img src={img11} />
        <img src={img12} />
        <img src={img13} />
        <img src={img14} />
        <img src={img15} />
        <img src={img16} />
      </div>
      <a href="https://5amclub.typeform.com/to/lI1P2Be1" className="underline">
        <div className="btn-join-now">
          Secure Your Spot<i className="bx bx-link-external topen"></i>
        </div>
      </a>
    </div>
  );
}
